.header {
  width: 100%;
  min-width: 320px;
  background-color: #3f51b5
}

.header-row {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  min-width: 320px;
  box-sizing: border-box;
  margin: auto;
  overflow-x: hidden;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center
}

.logo {
  color: #fff;
  text-align: left;
  user-select: none;
  margin: 0;
  font-style: italic;
  display: inline-block
}

.logo-badge {
  color: #fff;
  font-size: 1rem
}

.repo-link {
  color: #fff;
  text-decoration: none;
  line-height: 16px;
  display: flex
}

.navigation {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  min-width: 320px;
  box-sizing: border-box;
  margin: auto;
  overflow-x: hidden;
  padding: 20px;
  height: auto;
  padding: 0 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  margin-top: 15px
}

.navigation-item {
  position: relative;
  display: inline-block;
  padding: 10px 15px;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 1rem;
  font-style: italic;
  font-weight: bold;
  color: #435794;
  white-space: nowrap
}

.navigation-item:after {
  content: '/';
  position: absolute;
  font-size: 1.4rem;
  font-weight: 300;
  top: 4px;
  right: -4px;
  color: #e3e7f3
}

.navigation-item:last-child:after {
  content: ''
}

.e-eye {
  display: inline-block;
  width: 13px;
  height: 13px;
  border: solid 1px #000;
  border-radius: 75% 15%;
  position: relative;
  transform: rotate(45deg);
  cursor: pointer
}

.e-eye.__active:before {
  background-color: #000
}

.e-eye:before {
  content: '';
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border: solid 1px #000;
  border-radius: 50%;
  left: 3px;
  top: 3px
}

.s-code {
  margin-top: 10px;
  margin-bottom: 30px
}

.s-code__item {
  position: relative
}

.s-code__layer {
  background-color: #fff;
  position: absolute;
  height: 110px;
  padding: 30px;
  width: 100%;
  bottom: 0;
  box-sizing: border-box;
  pointer-events: none;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%)
}

.s-code__button {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 10px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #C7CDDE
}

.b-code {
  padding: 30px;
  border-radius: 2px;
  border: solid 1px #C7CDDE;
  background-color: rgba(199, 205, 222, 0.3);
  overflow-x: auto
}

.b-code pre {
  margin: 0;
  font-size: 14px;
  line-height: 1.4
}

.b-code .hljs-keyword,
.b-code .hljs-selector-tag,
.b-code .hljs-subst {
  color: #07a
}

.b-code.__hidden {
  height: 50px;
  overflow-y: hidden
}

.alice-carousel .animated {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.alice-carousel .animated-out {
  z-index: 1
}

.alice-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }

  100% {
    opacity: 0;
    visibility: hidden
  }
}

.alice-carousel {
  position: relative;
  width: 100%;
  margin: auto;
  direction: ltr
}

.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: auto
}

.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden
}

.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  vertical-align: top;
  white-space: normal;
  line-height: 0
}

.alice-carousel__stage-item * {
  line-height: initial
}

.alice-carousel__stage-item.__hidden {
  opacity: 0;
  overflow: hidden
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 10px 5px
}

.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  position: relative;
  content: attr(data-area);
  text-transform: capitalize
}

.alice-carousel__prev-btn {
  text-align: right
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  color: #465798
}

.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: darkred
}

.alice-carousel__prev-btn-item.__inactive,
.alice-carousel__next-btn-item.__inactive {
  opacity: 0.4
}

.alice-carousel__play-btn {
  position: absolute;
  top: 30px;
  left: 20px;
  display: inline-block
}

.alice-carousel__play-btn:hover {
  cursor: pointer
}

.alice-carousel__play-btn-wrapper {
  position: relative;
  width: 32px;
  height: 32px;
  padding: 10px;
  border-radius: 50%;
  background-color: #fff
}

.alice-carousel__play-btn-item {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: transparent
}

.alice-carousel__play-btn-item::before,
.alice-carousel__play-btn-item::after {
  position: absolute;
  pointer-events: none;
  display: block;
  width: 0;
  height: 0;
  content: '';
  transition: all .4s linear;
  border-width: 8px 0 8px 15px;
  border-style: solid;
  border-color: transparent;
  border-left-color: #465798
}

.alice-carousel__play-btn-item::before {
  left: 5px;
  height: 14px
}

.alice-carousel__play-btn-item::after {
  top: 7px;
  left: 18px
}

.alice-carousel__play-btn-item.__pause::before,
.alice-carousel__play-btn-item.__pause::after {
  height: 30px;
  border-width: 0 0 0 10px
}

.alice-carousel__play-btn-item.__pause::after {
  top: 0;
  left: 18px
}

.alice-carousel__dots {
  margin: 30px 3px 5px;
  padding: 0;
  list-style: none;
  text-align: center
}

.alice-carousel__dots>li {
  display: inline-block
}

.alice-carousel__dots-item:not(.__custom) {
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #e0e4fb
}

.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 20px
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #6e7ebc
}

.alice-carousel__slide-info {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 5px 10px;
  color: #465798;
  border-radius: 5px;
  background-color: rgba(224, 228, 251, 0.6)
}

.alice-carousel__slide-info-item {
  vertical-align: middle;
  line-height: 0
}

.autoheight .alice-carousel__wrapper {
  box-sizing: initial
}

/*

github.com style (c) Vasily Polovnyov <vast@whiteants.net>

*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #333;
  background: #f8f8f8;
}

.hljs-comment,
.hljs-quote {
  color: #998;
  font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: #333;
  font-weight: bold;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #008080;
}

.hljs-string,
.hljs-doctag {
  color: #d14;
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
  color: #900;
  font-weight: bold;
}

.hljs-subst {
  font-weight: normal;
}

.hljs-type,
.hljs-class .hljs-title {
  color: #458;
  font-weight: bold;
}

.hljs-tag,
.hljs-name,
.hljs-attribute {
  color: #000080;
  font-weight: normal;
}

.hljs-regexp,
.hljs-link {
  color: #009926;
}

.hljs-symbol,
.hljs-bullet {
  color: #990073;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #0086b3;
}

.hljs-meta {
  color: #999;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.alice-carousel__wrapper {
  border: solid 1px rgba(67, 87, 148, 0.3)
}

li.alice-carousel__dots-item.__custom {
  vertical-align: middle;
  cursor: pointer;
  padding: 5px
}

.item {
  height: 118px;
  padding: 2px;
  box-sizing: border-box;
  font-size: 0;
  color: transparent
}

.item:before {
  content: attr(data-value);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 300;
  user-select: none;
  color: #fff;
  background-color: #C7CDDE
}

body,
html,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Roboto, Helvetica, sans-serif;
  font-size: 1rem
}

.app {
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #fff
}

.app .title {
  font-weight: 300;
  font-style: italic;
  color: #435794
}

.app .title span {
  text-transform: capitalize
}

.app .anchor {
  opacity: 0.5
}

.app .anchor:hover {
  opacity: 1
}

.s-main {
  width: 100%;
  height: 100%;
  max-width: 1200px;
  min-width: 320px;
  box-sizing: border-box;
  margin: auto;
  overflow-x: hidden;
  padding: 20px;
  height: auto;
  padding-top: 0
}

.s-render-components {
  color: #435794
}

.b-refs-buttons {
  margin: 15px auto;
  text-align: center
}

.b-refs-buttons button {
  outline: none;
  color: #fff;
  border: none;
  text-decoration: none;
  background-color: #C7CDDE;
  margin: 0 5px;
  padding: 5px 15px;
  border-radius: 2px
}

.b-refs-navs {
  margin-top: 20px;
  text-align: center
}

.b-refs-navs i {
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 5px;
  background-color: #C7CDDE;
  border-radius: 6px
}


.alice-carousel__stage-item {
  width: 239px !important
}


.displayArea {
  width: 95%;
  margin: auto !important;
  padding: 5px;
}

.containerx {
  width: 100%;
}

/* --------------------- */
.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}


.custom-root {
  background-color: #fff;
}

.custom-divider {
  background-color: #000;
}

.custom-selected {
  background-color: #f00;
}














* {
  box-sizing: border-box !important;
}


h3 {
  font-weight: 400 !important;
  font-size: 16px !important;
}

p {
  font-size: 12px !important;
  color: #888 !important;
}

.stage {
  max-width: 80% !important;
  margin: 60px 10% !important;
  position: relative !important;
}

.folder-wrap {
  display: flex !important;
  flex-wrap: wrap !important;
}

.folder-wrap::before {
  display: block !important;
  position: absolute !important;
  top: -40px !important;
}

.folder-wrap:first-child::before {
  display: block !important;
  position: absolute !important;
  top: -40px !important;
}

.tile {
  border-radius: 3px !important;
  width: calc(16% - 17px) !important;
  margin-bottom: 23px !important;
  text-align: center !important;
  border: 1px solid #5b5b5b !important;
  transition: 0.2s all cubic-bezier(0.4, 0.0, 0.2, 1) !important;
  position: relative !important;
  padding: 35px 16px 25px !important;
  margin-right: 17px !important;
  cursor: pointer !important;
}

.tile:hover {
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.8) !important;
}

.tile i {
  color: #00A8FF !important;
  height: 55px !important;
  margin-bottom: 20px !important;
  font-size: 55px !important;
  display: block !important;
  line-height: 54px !important;
  cursor: pointer !important;
}

.tile i.mdi-file-document {
  color: #8fd9ff !important;
}

.ml-1 {
  margin: 0 12px 0 2px !important;
  font-size: 24px !important;
}

.back {
  font-size: 26px !important;
  border-radius: 50px !important;
  background: #00a8ff !important;
  border: 0 !important;
  color: white !important;
  width: 60px !important;
  height: 60px !important;
  margin: 20px 20px 0 !important;
  outline: none !important;
  cursor: pointer !important;
}

/* Transitioning */
.folder-wrap {
  position: absolute !important;
  width: 100% !important;
  transition: .365s all cubic-bezier(.4, 0, .2, 1) !important;
  pointer-events: none !important;
  opacity: 0 !important;
  top: 0 !important;
}

.folder-wrap.level-up {
  transform: scale(1.2) !important;

}

.folder-wrap.level-current {
  transform: scale(1) !important;
  pointer-events: all !important;
  opacity: 1 !important;
  position: relative !important;
  height: auto !important;
  overflow: visible !important;
}

.folder-wrap.level-down {
  transform: scale(0.8) !important;
}

.folderControl {
  font-size: 36px;
  margin: 4px 0 24px 0;
}

.slim-card .card-body {
  padding: 0 !important;
}

.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  font-size: 1.2rem;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  padding: 0 !important;
}

.hidden {
  display: none
}

.video1v {
  position: absolute;
  left: 0px;
  height: 1080px;
  top: 250px;
}

.video2v {
  position: absolute;
  left: 0px;
  height: 1080px;
  top: 1340px;
}

.video3v {
  position: absolute;
  left: 0px;
  height: 1080px;
  top: 2430px;
}

.video1h {
  position: absolute;
  left: 0px;
  height: 1080px;
  top: 250px;
}

.video2h {
  position: absolute;
  left: 1930px;
  height: 1080px;
  top: 250px;
}

.video3h {
  position: absolute;
  left: 3850px;
  height: 1080px;
  top: 250px;
}

.video1t {
  position: absolute;
  height: 540px;
  left: 0;
}

.video2t {
  position: absolute;
  height: 540px;
  left: 960px;
}

.video3t {
  position: absolute;
  height: 540px;
  left: 1920px;
}

#pause {
  display: none
}

.expanded-view {
  max-width: 100% !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #fff !important;
}

.dropdown-item a:hover {
  color: #007bff;
  text-decoration: none;
}

/* Library & Context Menu styling */
.menu {
  font-size: 16px;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 5px;
  padding: 6px 0 6px 0;
  width: 200px;
  height: auto;
  margin: 0;
  position: absolute;
  box-shadow: 0 0 20px 0 #ccc;
}

.menu-item {
  padding: 0.25em 0.6em;
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menu-item:hover {
  background-color: #C5DBFC;
  border-left: 4px solid #000;
}

.divider {
  border-bottom: 2px solid #000;
  margin: 6px 0;
}

.menuOptions {
  width: 100%;
  height: 32px;
  padding-left: 8px;
  padding-top: 6px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  list-style-type: none;
}

.activeFolders {
  width: 100%;
  height: 32px;
  padding-left: 8px;
  padding-top: 6px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  list-style-type: none;
  background-color: #C5DBFC;
}

.menuIcons {
  width: 16px;
  height: 16px;
  font-size: 16px;
  margin-right: 18px;
}

.contextMenuIcons {
  color: #007bff;
  width: 18px !important;
  height: 18px !important;
  margin-right: 14px;
}

.treeViewIcons {
  font-size: 22px;
}

.folderIcons {
  font-size: 24px;
}

.searchIcon {
  font-size: 22px;
  float: left;
  margin-left: 70px;
  margin-right: 6px;
  margin-top: 6px;
}

.userSearchBar {
  width: 60% !important;
  float: left !important;
  margin-bottom: 12px !important;
}

.lockedIcon {
  font-size: 16px;
  position: absolute;
  top: 4px;
  left: 4px;
}

.folderDivider {
  border-bottom: 1px solid #000;
  margin: 6px 0 4px 0;
  list-style-type: none;
}
